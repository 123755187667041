<template>
  <AppNavBar/>
  <vue-cookie-accept-decline
  :debug="false"
  :disableDecline="false"
  :showPostponeButton="false"
  @clicked-accept="cookieClickedAccept"
  @clicked-decline="cookieClickedDecline"
  @clicked-postpone="cookieClickedPostpone"
  @removed-cookie="cookieRemovedCookie"
  @status="cookieStatus"
  elementId="myPanel1"
  position="bottom-left"
  ref="myPanel1"
  transitionName="slideFromBottom"
  type="floating"
>
  <!-- Optional -->
  <template #postponeContent>&times;</template>

  <!-- Optional -->
  <template #message>
    <h3>Gestion des cookies</h3>
    <p class="text-xs"> Hopinnov et ses partenaires utilisent des Cookies strictement nécessaires, de navigation et marketing et statistiques. Acceptez le dépôt de tous nos cookies en cliquant sur "Accepter" ou sélectionnez les catégories de cookies déposés en cliquant sur "Voir les préférences" sinon refusez nos cookies non strictement nécessaire en cliquant sur "Refuser".
    <span class="text-color-primary" ><router-link :to="{ name: 'confidentiality' }">
			Retrouvez notre politque de confidentialité
		</router-link></span>
    </p>
  </template>

  <!-- Optional -->
  <template #declineContent>
    Refuser
  </template>

  <!-- Optional -->
  <template #acceptContent>
    Accepter
  </template>

</vue-cookie-accept-decline>
  <router-view></router-view>
  <AppFooter/>
</template>

<script>
import AppNavBar from '@/components/AppNavBar.vue'
import AppFooter from '@/components/AppFooter.vue'
import EventBus from './EventBus.js';


export default {
  data () {
    return {
      status: null,
    };
  },
  created() {
    EventBus.$on('remove-cookie-clicked', this.removeCookie);
  },
  methods: {
    removeCookie() {
      this.$refs.myPanel1.removeCookie();
    },
    cookieStatus (status) {
      this.status = status;
    },
    cookieClickedAccept () {
      this.status = 'accept';
    },
    cookieClickedDecline () {
      this.status = 'decline';
      // insert here code to remove cookie if necessary
    },
    cookieClickedPostpone () {
      this.status = 'postpone';
    },
    cookieRemovedCookie () {
      console.log('here in cookieRemoved');
      this.status = null;
      this.$refs.myPanel1.init();
    },
    removeCookie () {
      this.$refs.myPanel1.removeCookie();
      this.$refs.myPanel1.removeCookie();
    },
  },
  computed: {
    statusText () {
      return this.status || 'No cookie set';
    },
  },
  components: {
    AppNavBar,
    AppFooter,
  }
}

</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;800&display=swap');

</style>
