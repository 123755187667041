/* eslint-disable */
<template>
    <div class="h-[100%]">
    <section id="news" class="container xl:max-w-screen-xl mx-auto px-10 py-20 sticky top-0">
            <h2 class="mb-0">Les actualités</h2>
            <p>Réjoignez-nous sur <span class="bg-color-secondary rounded-full w-8 h-8 mb-4 inline-flex"><a href="https://www.linkedin.com/company/hopinnov/" target="_blank" class="text-color-primary text-l m-auto"><font-awesome-icon :icon="[ 'fab', 'linkedin-in' ]" /></a></span></p>
            <div v-if="postsByCategoryNews.length > 0" class="columns-2xs">
                <NewsPreviewComponent
                v-for="post in postsByCategoryNews"
                :id="post.id"
                :title="post.title"
                :date="post.date"
                :blurb="post.blurb">
                </NewsPreviewComponent>
            </div>
            <div v-else>
                <p>Retrouvez nos actualités sur notre compte linkedin.</p>
            </div>
    </section>
    </div>
</template>

<script setup>
      import { ref, onMounted, computed } from "vue";
      import NewsPreviewComponent from './NewsPreviewComponent.vue';
      import { usePostStore } from "../stores/PostStore.js"; 

      const store = usePostStore();

      const postsByCategoryNews = computed(() => {
      return store.postsByCategoryNews;
      });
      onMounted(() => {
      store.fetchPostsByCategoryNews(1);
      });

</script>