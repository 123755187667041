<template>
    <section id="publication" class="container xl:max-w-screen-xl mx-auto px-10 py-20">
      <h2>Les Publications</h2>
  
      <!-- Category Menu -->
      <div class="mb-4">
        <a
          @click="fetchPostsByCategory(null)"
          :class="{ 'font-bold': selectedCategoryId === null }"
          class="mr-4 cursor-pointer text-color-primary hover:underline"
        >
          Tous les posts
        </a>
        <a
          v-for="category in categories.categories"
          :key="category.id"
          @click="fetchPostsByCategory(category.id)"
          :class="{ 'font-bold': selectedCategoryId === category.id }"
          class="mr-4 cursor-pointer text-color-primary hover:underline"
        >
          {{ category.name }}
        </a>
      </div>
  
      <!-- Posts -->
      <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
        <PostPreviewComponent
          v-for="post in postsByCategory"
          :key="post.id"
          :id="post.id"
          :title="post.title"
          :date="post.date"
          :blurb="post.blurb"
          :image="post.image"
        ></PostPreviewComponent>
      </div>
  
      <!-- Navigation Button -->
      <div class="text-center mt-4">
        <router-link :to="{ name: 'home' }">
          <button>
            Retourner à l'accueil
          </button>
        </router-link>
      </div>
    </section>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import PostPreviewComponent from '../components/PostPreviewComponent.vue';
  import { usePostStore } from '../stores/PostStore.js';
  import { useCategoryStore } from '../stores/CategoryStore.js';
  
  const postStore = usePostStore();
  const categoryStore = useCategoryStore();
  
  const categories = ref([]);
  const selectedCategoryId = ref(null);
  
  const fetchPostsByCategory = async (categoryId) => {
    selectedCategoryId.value = categoryId;
    await postStore.fetchPostsByCategory(categoryId);
  };
  
  const postsByCategory = computed(() => {
    return selectedCategoryId.value !== null ? postStore.postsByCategory : postStore.posts.posts;
  });
  
  onMounted(async () => {
    document.title = "Hopinnov - Innover pour des pratiques durables et des ...";
    await categoryStore.fetchCategories();
    categories.value = categoryStore.categories;
    await postStore.fetchPosts();
  });

  </script>
  