<template>
    <div class="inline-block border-solid border-2 rounded-md p-4 my-4  border-color-lightgrey bg-white drop-shadow-none	transition-all duration-300 hover:drop-shadow-xl">
        <blockquote>
            <h4>{{quote}}</h4>
            <footer>
                <small>
                   - {{author}}
                </small>
            </footer>
        </blockquote>
    </div>
</template>

<script>
   export default {
        name: 'TestimonialSingleComponent',
        props: {
            author: String, 
            quote: String,
            type: String,
            },
        };
</script>