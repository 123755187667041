<template>
    <section class="container xl:max-w-screen-xl mx-auto px-10 py-20 md:flex flex-row items-center">
			<div class="basis-1/3">
				<img src="../assets/img/404.png" alt="illustration d'un personnel de santé">
			</div>
			<div class="basis-2/3">
				<h2 class="mb-0 text-color-secondary text-5xl">Erreur 404</h2>
				<h3>Page non trouvée</h3>
				<p> Nous n'arrivons pas à trouver la page que vous recherchez</p>
				<router-link :to="{ name: 'home' }">
				<button> Retourner à la page d'Accueil </button>
				</router-link>

			</div>
		</section>
</template>


<script>
export default {
    mounted() {
    document.title = "Hopinnov - Innover pour des pratiques durables et des ...";
  },
}
</script>