import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { VueHeadMixin, createHead } from '@unhead/vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import VueMatomo from 'vue-matomo'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faHandsPraying } from '@fortawesome/free-solid-svg-icons'
import { faHandshakeSimple } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faLocationDot, faEnvelope, faPhone, faHandsPraying, faHandshakeSimple, faLinkedin, faLinkedinIn, faLock, faStopwatch, faMoneyBillWave, faBars, faXmark)

const pinia = createPinia()
const head = createHead()

createApp(App)
.use(router)
.use(pinia)
.use(VueMatomo, {
    host: 'https://analytics.hopinnov.fr',
    siteId: 12,
  })
.mixin(VueHeadMixin)
.component('font-awesome-icon', FontAwesomeIcon)
.component('vue-cookie-accept-decline', VueCookieAcceptDecline)
.mount('#app')
 
window._paq.push(['trackPageView']);