<template>
    <section class="container xl:max-w-screen-xl mx-auto p-10 pt-20">
        <LegalSingleComponent
                v-for="legals in legals.legalMention"
                :key="legals.id"
                :title="legals.title"
                :content="legals.content">
          </LegalSingleComponent>
    </section>        
</template>

<script setup>
    import { ref, onMounted, computed } from "vue";
    import LegalSingleComponent from '../components/LegalSingleComponent.vue';
    import { useLegalMentionStore } from "../stores/LegalMentionStore.js"; 

    const store = useLegalMentionStore();

    const legals = computed(() => {
    return store.legals;
    });
    onMounted(() => {
    store.fetchLegals();
    });

</script>

