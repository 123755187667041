/* eslint-disable */
<template>
    <section id="benefit" class="container xl:max-w-screen-xl mx-auto px-10 py-20">
        <h2>Les bénéfices</h2>
        <div class="md:grid md:grid-cols-2 lg:grid-cols-4 gap-4">
          <BenefitSingleComponent
                v-for="benefit in benefits.benefits"
                :key="benefit.id"
                :highlight="benefit.highlight"
                :text="benefit.text"
                :icon="benefit.icon"
                :footnotes="benefit.footnotes">
          </BenefitSingleComponent>
       </div>
    </section>
</template>

<script setup>
    import { ref, onMounted, computed } from "vue";
    import BenefitSingleComponent from './BenefitSingleComponent.vue';
    import { useBenefitStore } from "../stores/BenefitStore.js"; 

    const store = useBenefitStore();

    const benefits = computed(() => {
    return store.benefits;
    });
    onMounted(() => {
    store.fetchBenefits();
    });

</script>