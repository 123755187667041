<template>
  <div>
    <template v-if="shouldRenderComponent">
      <SinglePostComponent
        :title="post.title"
        :date="post.date"
        :image="post.image"
        :content="post.content"
      ></SinglePostComponent>
    </template>
    <template v-else>
      <p>Chargement...</p>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import SinglePostComponent from '../components/SinglePostComponent.vue';
import { usePostStore } from "../stores/PostStore.js"; 
import { useRouter, useRoute } from 'vue-router';

const store = usePostStore();
const route = useRoute();
let postId = route.params.id;

const post = computed(() => {
    // Fetch the posts if not already fetched
    if (!store.getAllPosts.length) {
        store.fetchPosts();
    }
    // Set the single post based on the postId
    store.setSinglePostById(postId);
    const calculatedPost = store.getSinglePost;
    return calculatedPost;
});

onMounted(async () => {
    // Fetch the posts if not already fetched
    if (!store.getAllPosts.length) {
        await store.fetchPosts();
    }
    // Now that fetchPosts is completed, set the single post based on the postId
    await store.setSinglePostById(postId);
});

// Ensure post is not null before rendering the component
const shouldRenderComponent = computed(() => !!post.value);

</script>
