/* eslint-disable */
<template>
    <section id="contact" class="container xl:max-w-screen-xl mx-auto px-10 py-20 md:flex flex-row items-center">
            <form  class="basis-1/2 grid grid-cols-2 gap-2" id="form">
                <h2 class="mb-0 col-span-2">Contactez-nous</h2>     
                <p class="col-span-2">Les champs marqués d'un astérique * sont obligatoires.</p>
                <div class="col-span-2 lg:col-span-1">
                    <label for="firstname">Prénom *</label>
                    <input type="text" v-model="contact.firstname" @blur="validate('firstname')" 
                    @keypress="validate('firstname')" class="text-input" required>
                    <p v-if="!!errors.firstname" class="bg-red-100 text-red-600 rounded py-1 px-2"> {{errors.firstname}}</p>
                </div>
                <div class="col-span-2 lg:col-span-1">
                    <label for="lastname">Nom *</label>
                    <input type="text" v-model="contact.lastname" @blur="validate('lastname')" 
                    @keypress="validate('lastname')" class="text-input" required>
                    <p v-if="!!errors.lastname" class="bg-red-100 text-red-600 rounded py-1 px-2"> {{errors.lastname}}</p>
                  </div>
                <div class="col-span-2 lg:col-span-1">
                    <label for="mail">Email *</label>
                    <input type="email" v-model="contact.email" @blur="validate('email')" 
                    @keypress="validate('email')" class="text-input" required>
                    <p v-if="!!errors.email" class="bg-red-100 text-red-600 rounded py-1 px-2"> {{errors.email}}</p>
                  </div>
                <div class="col-span-2 lg:col-span-1">
                    <label for="mail">Téléphone</label>
                    <input type="tel" v-model="contact.phone"  @blur="validate('phone')" 
                    @keypress="validate('phone')" class="text-input">
                    <p v-if="!!errors.phone" class="bg-red-100 text-red-600 rounded py-1 px-2"> {{errors.phone}}</p>
                </div>
                <div class="col-span-2">
                    <label for="msg">Message *</label>
                    <textarea v-model="contact.message" @blur="validate('message')" 
                    @keypress="validate('message')" class="text-input" required></textarea>
                    <p v-if="!!errors.message" class="bg-red-100 text-red-600 rounded py-1 px-2"> {{errors.message}}</p>
                  </div>
                  <div class="col-span-2 flex">
                    <input type="checkbox" v-model="isCheckboxChecked">
                    <p class="text-sm ml-4">Les données du formulaire peuvent être utilisées par Hopinnov à des fins commerciales. Consultez notre <a href="#" class="text-color-primary"> <router-link to="/politique-confidentialité"> Politique de confidentialité </router-link></a> pour en savoir plus.</p>                    
                  </div>
                <p v-if="form===true" class="col-span-2 bg-green-100 text-green-600 rounded py-1 px-2"> Message envoyé</p>
                <button @click.prevent="submitForm" class="col-span-2" :disabled="!isCheckboxChecked" :class="{'bg-slate-300 cursor-not-allowed': !isCheckboxChecked, 'hover:bg-slate-400': !isCheckboxChecked }"><input type="submit" value="ENVOYER" ></button>
            </form>

            <div class="bg-color-secondary basis-1/2 flex flex-col mt-6 p-6 md:m-20 lg:p-14 items-baseline" v-if="contactData" v-for="(data, index) in contactData.contact" :key="index">
                <div class="flex flex-row items-baseline mb-1">
                        <font-awesome-icon icon="fa-solid fa-phone" />
                        <p class="ml-6"> <a :href="`tel:${data.phone}`">{{ data.phone }}</a></p>
                </div>
                <div class="flex flex-row items-baseline mb-1">
                    <font-awesome-icon icon="fa-solid fa-envelope" />
                        <p class="ml-6"><a :href="`mailto:${data.email}`"> {{ data.email }} </a></p>
                </div>
                <div class="flex flex-row items-baseline mb-1">
                    <font-awesome-icon icon="fa-solid fa-location-dot" />
                        <p class="ml-6">{{ data.address }}</p>
                </div>
            </div>
    </section>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue';
import axios from 'axios';
import { object, string, number } from 'yup';
import { useContactStore } from "../stores/ContactStore.js"; 
import { API_BASE_URL } from '@/apiConfig';

const store = useContactStore();

const contact = reactive({
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  message: '',
});

const errors = reactive({
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  message: '',
});

const form = ref(false);

const validationSchema = object().shape({
  email: string().email('Adresse mail non valide').required('Adresse mail requise'),
  firstname: string().required('Prénom requis'),
  lastname: string().required('Nom requis'),
  phone: string().nullable(),
  message: string().required('Message requis'),
});

const isCheckboxChecked = ref(false);

const submitForm = async () => {
  try {

    // Check if the checkbox is checked before submitting the form
    if (!isCheckboxChecked.value) {
      // If the checkbox is not checked, you can handle this case accordingly
      console.warn('Please agree to the terms before submitting the form.');
      return;
    }
    // Validate the form data
    await validationSchema.validate(contact, { abortEarly: false });

    // Reset the form
    document.getElementById('form').reset();

    // Make a POST request to the backend API
    const response = await axios.post(`${API_BASE_URL}/api/submit-form`, {
      firstname: contact.firstname,
      lastname: contact.lastname,
      email: contact.email,
      phone: contact.phone,
      message: contact.message,
    }, { withCredentials: true });

    // Update the form status to show success message
    form.value = true;
  } catch (error) {
    // Log any errors
    console.error('Error:', error);

    // You can handle specific error cases if needed
    if (error.response) {
      console.error('Server responded with a non-2xx status:', error.response.data);
      console.error('Status code:', error.response.status);
    } else if (error.request) {
      console.error('No response received from the server');
    } else {
      console.error('Error setting up the request:', error.message);
    }
  }
};

const validate = (field) => {
  validationSchema
    .validateAt(field, contact)
    .then(() => (errors[field] = ''))
    .catch((err) => {
      errors[err.path] = err.message;
    });
};

const contactData = computed(() => {
    const contact = store.contact || {};
    return contact;
});

onMounted(() => {
  store.fetchContact();
});
</script>


