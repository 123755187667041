<template>
    <div class="flex flex-col bg-white drop-shadow-none	transition-all duration-500 hover:drop-shadow-xl pt-6 pb-16">
        <div class="bg-color-secondary rounded-full w-24 h-24 mt-4 mb-4 flex items-center self-center">
            <!-- <font-awesome-icon class="text-color-primary text-3xl m-auto" icon="fa-solid fa-hands-praying" /> -->
            <font-awesome-icon class="text-color-primary text-3xl m-auto" :icon="`${icon}`" />
        </div>

        <p class="font-extrabold text-color-darkergrey text-center">{{highlight}}</p>
        <p class="text-center">{{text}}</p>
        <small class="text-center">{{footnotes}}</small>
    </div>
</template> 

<script>
   export default {
        name: 'BenefitSingleComponent',
        props: {
            highlight: String, 
            text: String,
            icon: String,
            footnotes: String
            },
        };
</script>