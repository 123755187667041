<template>
    <nav class="topnav" id="myTopnav">
        <div>
            <router-link :to="{ name: 'home' }" class="logo">
            <img alt="Hopinnov logo" src="../assets/img/hopinnov.png" class="w-40" width="160">
            </router-link>
        </div>
        <div>
            <a href="/#testimonial" class="text-color-grey hover:text-color-darkergrey py-4 pr-4">Témoignages</a>
            <a href="/#benefit" class="text-color-grey hover:text-color-darkergrey py-4 pr-4">Bénéfices</a>
            <a href="/#service" class="text-color-grey hover:text-color-darkergrey py-4 pr-4">Notre solution</a>
            <a href="/#contact" class="text-color-grey hover:text-color-darkergrey py-4 pr-4">Contact</a>
            <a href="javascript:void(0);" class="icon text-color-grey text-2xl" @click="navBar">
                <font-awesome-icon :icon="['fas', icon ]" />
            </a> 
        </div>
    </nav>

</template>

<script>
    export default {
        data(){ 
            return {
                icon : 'fa-bars',
            }
        },
        methods: {
            navBar() {
                    var x = document.getElementById("myTopnav");
                if (x.className === "topnav") {
                    x.className += " responsive";
                    this.icon = 'fa-xmark';
                } else {
                    x.className = "topnav";
                    this.icon='fa-bars';
                }
            }
        }
    }
</script>

<style>
        .topnav {
        overflow: hidden;
        }
        .topnav .icon {
        display: none;
        }

        @media screen and (max-width: 600px) {
            .topnav a:not(.logo) {
                display: none;
            }
            .topnav a.icon {
                float: right;
                display: block;
            }
            .topnav.responsive {
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column;
            }
            .topnav.responsive .icon {
                position: absolute;
                right: 2.5rem;
                top: 1rem;
            }
            .topnav.responsive a {
                float: none;
                display: block;
                text-align: left;
            }
        }
</style>