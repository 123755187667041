<template>
    <div class="inline-block border-solid border-2 rounded-md my-4  border-color-lightgrey bg-white drop-shadow-none	transition-all duration-300 hover:drop-shadow-xl">
        <img :src="`${image}`" alt="" class="w-full h-52 object-cover">
        <blockquote class="p-4">
            <h4 class="text-l font-extrabold text-color-darkergrey leading-5">{{title}}</h4>
            <time>{{ date }}</time>
            <p class="leading-5 mt-4 mb-4">{{blurb}} [...]</p>
            <router-link :to="{ name: 'singlepostview', params: {id}}">
                <a href="#" class="text-color-primary"> Continuer à lire</a>
			</router-link>
        </blockquote>
    </div>
</template>

<script>
   export default {
        name: 'PostPreviewComponent',
        props: {
            id : {
            type:Number,
            required: true
            },
            title: String, 
            date: String,
            blurb: String,
            Image: String,
            },
        };
</script>