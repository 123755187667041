<template>
    <section class="container xl:max-w-screen-xl mx-auto p-10 pt-20">
        <ConfidentialitySingleComponent
                v-for="confidentiality in confidentiality.confidentiality"
                :key="confidentiality.id"
                :title="confidentiality.title"
                :content="confidentiality.content">
        </ConfidentialitySingleComponent>
    </section>        
</template>

<script setup>
    import { ref, onMounted, computed } from "vue";
    import ConfidentialitySingleComponent from '../components/ConfidentialitySingleComponent.vue';
    import { useConfidentialityStore } from "../stores/ConfidentialityStore.js"; 

    const store = useConfidentialityStore();

    const confidentiality = computed(() => {
    return store.confidentiality;
    });
    onMounted(() => {
    store.fetchConfidentiality();
    });

</script>

