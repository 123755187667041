import { defineStore } from "pinia";
import { API_BASE_URL } from '@/apiConfig';
import { accessToken } from '@/apiConfig';
import axios from "axios";

export const useBenefitStore = defineStore("BenefitStore", {

    state: () => ({
        benefits: [],
    }),

    getters: {
        getBenefits(state) {
            return state.benefits;
        },
    },

    actions: {
        async fetchBenefits() {
            this.benefits = []
            try {
                const data = await axios.get(`${API_BASE_URL}/api/benefits`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                this.benefits = data.data;
                console.log('benefits',this.benefits)
            } catch (error) {
                console.error(error);
            }
        }
    },

});
