import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LegalNoticesView from '../views/LegalNoticesView.vue';
import ConfidentialityView from '../views/ConfidentialityView.vue';
import NotFoundView from '../views/NotFoundView.vue';
import SinglePostView from '../views/SinglePostView.vue';
import AllPostView from '../views/AllPostView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/mentions-légales',
    name: 'legalnotices',
    component: LegalNoticesView
  },
  {
    path: '/politique-confidentialité',
    name: 'confidentiality',
    component: ConfidentialityView
  },
  {
    path: '/:match(.*)',
    name: 'notfound',
    component: NotFoundView
  },
  {
    path: '/posts/:id',
    name: 'singlepostview',
    component: SinglePostView
  },
  {
    path: '/posts',
    name: 'allpostview',
    component: AllPostView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
