<template>
    <header class="bg-color-secondary pt-12">
        <section class="container xl:max-w-screen-xl mx-auto px-10 py-20 md:flex flex-row items-center">
          <div class="basis-1/2" v-for="(data, index) in header.header" :key="index">
              <h1>{{ data.title }} </h1>
              <p>{{ data.paragraph }} </p>
              <button>
                <a href="/#video">{{ data.button }} </a>
              </button>
          </div>
          <div class="basis-1/2 mt-8 md:mt-0">
              <img src="../assets/img/visuel-hopinnov.png" width="600" alt="réseau entre le personnel hospitalier et la solution digitale d'Hopinnov : POC et PICK">          
          </div>
        </section> 
    </header>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue';
import { useHeaderStore } from "../stores/HeaderStore.js"; 

const store = useHeaderStore();

const header = computed(() => {
    return store.header;
});

onMounted(() => {
  store.fetchHeader();
});
</script>
