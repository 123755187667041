/* eslint-disable */
<template>
    <section id="testimonial" class="container xl:max-w-screen-xl mx-auto px-10 py-20">
        <h2>Ils nous font confiance</h2>
        <div class="columns-2xs">
        <TestimonialSingleComponent
          v-for="testimonial in testimonials.testimonials"
          :author="testimonial.author"
          :quote="testimonial.quote">
        </TestimonialSingleComponent>
        </div>
    </section>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
 import TestimonialSingleComponent from './TestimonialSingleComponent.vue';
 import { useTestimonialStore } from "../stores/TestimonialStore.js"; 

const store = useTestimonialStore();

const testimonials = computed(() => {
  return store.testimonials;
});
onMounted(() => {
  store.fetchTestimonials();
});

</script>
