import { defineStore } from "pinia";
import { API_BASE_URL } from '@/apiConfig';
import { accessToken } from '@/apiConfig';
import axios from "axios";

export const useConfidentialityStore = defineStore("ConfidentialityStore", {

    state: () => ({
        confidentiality: [],
    }),

    getters: {
        getLegals(state) {
            return state.confidentiality;
        },
    },

    actions: {
        async fetchConfidentiality() {
            this.confidentiality = []
            try {
                const data = await axios.get(`${API_BASE_URL}/api/confidentiality`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                this.confidentiality = data.data;
                console.log('confidentiality',this.confidentiality)
            } catch (error) {
                console.error(error);
            }
        }
    },

});
