<template>
      <h2 class="mb-0 legal-wrap">{{title}}</h2>
      <p class="mb-8 legal-wrap">{{content}}</p>
</template> 

<script>
   export default {
        name: 'LegalSingleComponent',
        props: {
            title: String, 
            content: String,
            },
        };
</script>

<style>
    .legal-wrap {
        white-space: pre-wrap;
    }
</style>