import { defineStore } from "pinia";
import { API_BASE_URL } from '@/apiConfig';
import { accessToken } from '@/apiConfig';
import axios from "axios";

export const useContactStore = defineStore("ContactStore", {

    state: () => ({
        contact:  {
        },
    }),

    getters: {
        getContact(state) {
            return state.contact;
        },
    },

    actions: {
        async fetchContact() {
            try {
                const data = await axios.get(`${API_BASE_URL}/api/contact`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                });
                this.contact = data.data;
            } catch (error) {
                console.error(error);
            }
        }
    },

});
