<template>
    <section id="publication" class="container max-w-screen-md mx-auto px-10 py-20">
        <img class="w-full object-cover h-96" :src="`${image}`" alt="">
        <h2 class="mb-0">{{ title }}</h2>
        <time>{{date}}</time>
        <p>{{content}}</p>
        <router-link :to="{ name: 'home'}">
            <button href="#"> Retour à l'accueil</button>
        </router-link>
    </section>
</template>

<script>
   export default {
        name: 'SinglePostComponent',
        props: {
            title: String, 
            image: String,
            date: String,
            content: String,
            },
        };
</script>