<template>
    <div class="bg-color-darkergrey text-white">
        <section class="container xl:max-w-screen-xl mx-auto p-8 flex items-center justify-around">
		<div id="wcb" class="carbonbadge wcb-d"></div>
		<!-- <script src="https://unpkg.com/website-carbon-badges@1.1.3/b.min.js" defer></script> -->
	
		<img src="../assets/img/badgeCrisalide.png" class="img-fluid" alt="Badge lauréat du concours Crisalide numérique 2022 CCI Ile-et-Vilaine Bretagne compétitivité " width="150">
        </section>
    </div>
    <footer class="bg-color-darkgrey">
        <div class="container xl:max-w-screen-xl mx-auto p-4">   
            <p class="text-white text-center">© {{currentYear}} Hopinnov - 
            <router-link to="/mentions-légales">
			Mentions Légales -
			</router-link>
            <router-link to="/politique-confidentialité">
			Politique de confidentialité -
			</router-link>
            <span @click="handleRemoveCookieClick" style="cursor: pointer;">Cookies</span> -
               Rejoignez-nous sur
            <a href="https://www.linkedin.com/company/hopinnov/" target="_blank" class="text-white text-xl"><font-awesome-icon :icon="[ 'fab', 'linkedin' ]" /></a>
            </p>
        </div>
    </footer>
</template>

<script>
    import EventBus from '../EventBus.js';

    export default {
    data() {
        return {
        currentYear: new Date().getFullYear()
        }
    },
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://unpkg.com/website-carbon-badges@1.1.3/b.min.js')
      document.head.appendChild(recaptchaScript)
    },
    methods: {
    handleRemoveCookieClick() {
      EventBus.$emit('remove-cookie-clicked');
    },
    },
    }       
</script>