<template>

    <div id="service" class="bg-color-secondary">
      <section class="container xl:max-w-screen-xl mx-auto px-10 py-20">
        <h2>Notre solution</h2>
       <div class="md:flex flex-row items-center">
            <div  class="basis-1/2">
                  <img src="../assets/img/pocpick.png"  width="560" alt="Image représentant les deux solutions POC et PICK : Une première tablette avec une capture d'écrant d'un protocole opératoire pour POC et une seconde tablette avec un personnel de santé qui réalise un Picking (préparation de paniers de matériel pour une opération) en utilisant l'outil Pick ">
            </div>

            <div  class="basis-1/2 md:ml-20">
                  <ServiceSingleComponent
                        v-for="service in services.services"
                        :key="service.id"
                        :name="service.name"
                        :text="service.text"
                        :image="service.image"
                        >
                  </ServiceSingleComponent>
            </div>
      </div>
      </section>
     </div>

</template>

<script setup>
      import { ref, onMounted, computed } from "vue";
      import ServiceSingleComponent from './ServiceSingleComponent.vue';
      import { useServiceStore } from "../stores/ServiceStore.js"; 

      const store = useServiceStore();

      const services = computed(() => {
      return store.services;
      });
      onMounted(() => {
      store.fetchServices();
      });

</script>