<template>
 <div class="md:grid md:grid-cols-4">
    <div class="md:col-span-4"> 
    <HeaderComponent/>
    </div>
    <div class="order-first md:order-last md:shadow-[0_18px_50px_-10px_rgba(0,0,0,0.2)]">
      <NewsBarComponent/>
    </div>
    <div class="md:col-span-3">
      <VideoBannerComponent/>
      <TestimonialListComponent/>
      <BenefitListComponent/>
      <ServiceComponent/>
      <PublicationComponent/> 
      <ContactFormComponent/>
    </div>
  </div> 
</template>

<script>
// @ is an alias to /src
import HeaderComponent from '@/components/HeaderComponent.vue'
import TestimonialListComponent from '@/components/TestimonialListComponent.vue'
import BenefitListComponent from '@/components/BenefitListComponent.vue'
import ServiceComponent from '@/components/ServiceComponent.vue'
import ContactFormComponent from '@/components/ContactFormComponent.vue'
import NewsBarComponent from '@/components/NewsBarComponent.vue'
import VideoBannerComponent from '@/components/VideoBannerComponent.vue'
import PublicationComponent from '@/components/PublicationComponent.vue'
import { Head } from '@unhead/vue/components'

export default {
  name: 'HomeView',
  components: {
    HeaderComponent,
    TestimonialListComponent,
    BenefitListComponent,
    ServiceComponent,
    ContactFormComponent,
    NewsBarComponent,
    VideoBannerComponent,
    PublicationComponent
  },
  head() {
    return {
      title: 'Hopinnov - Innover pour des pratiques durables et des équipes sereines',
      meta: [
      {
        name: 'description',
        content: 'Hopinnov, la transition numérique en santé pour le bien être des équipes. POC&PICK digitalise les protocoles opératoires et sécurise la préparation de paniers',
      },
    ],
    }
  }
}


</script>
