/* eslint-disable */
<template>
    <section v-if="postsByCategory.length > 0" id="publication" class="container xl:max-w-screen-xl mx-auto px-10 py-20">
        <h2>Les Publications</h2>
        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          <PostPreviewComponent
          v-for="post in postsByCategory"
                :id="post.id"
                :title="post.title"
                :date="post.date"
                :blurb="post.blurb"
                :image="post.image">
          </PostPreviewComponent>
       </div>
       <div class="text-center mt-4">
            <router-link :to="{ name: 'allpostview' }">
                  <button>
                  Voir toutes les publications
                  </button>
            </router-link>
      </div>
    </section>
</template>

<script setup>
      import { ref, onMounted, computed } from "vue";
      import PostPreviewComponent from './PostPreviewComponent.vue';
      import { usePostStore } from "../stores/PostStore.js"; 

      const store = usePostStore();

      const postsByCategory = computed(() => {
      return store.postsByCategory;
      });
      onMounted(() => {
      store.fetchPostsByCategory(2);
      });

</script>