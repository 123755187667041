<template>
        <div class="mb-14">
            <img class="w-14 mb-1" :src="require(`../assets/img/${image}.png`)" alt="Logo Poc" width="56" height="56">
            <h3>{{name}}</h3>
            <p>{{text}}</p>
        </div>
</template>

<script>
   export default {
        name: 'ServiceSingleComponent',
        props: {
            name: String, 
            text: String,
            image: String,
            },
        };
</script>