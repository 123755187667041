import { defineStore } from "pinia";
import { API_BASE_URL } from '@/apiConfig';
import { accessToken } from '@/apiConfig';
import axios from "axios";

export const usePostStore = defineStore("PostStore", {
    state: () => ({
        post:null,
        posts: [],
        postsByCategory: [],
        postsByCategoryNews: [],
        postById: {},
        loading: false,
        error: null
    }),

    getters: {
        getAllPosts(state) {
            return state.posts;
        },
        getPostsByCategory(state) {
            return state.postsByCategory;
        },
        getSinglePost(state) {
            return state.post && state.post.id ? state.post : null;
        },
    },

    actions: {
        async fetchPosts() {
                try {
                    const { data } = await axios.get(`${API_BASE_URL}/api/posts`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        },
                    });
                    // Check if data.posts is an array
                    if (Array.isArray(data.posts)) {
                        // Set this.posts with the array of posts
                        this.posts = data.posts;
                        // Populate postById dictionary
                        this.posts.forEach(post => {
                            this.postById[post.id] = post;
                        });
                    } else {
                        console.error("Data received does not have an array of posts:", data);
                    }
                } catch (error) {
                    console.error(error);
                }
            },        
        setSinglePostById(postId) {
            const foundPost = this.postById[postId];
            if (foundPost && foundPost.id) {
                this.post = foundPost; 
            } else {
                console.error(`Post with ID ${postId} not found or missing 'id' property.`);
            }
        },        
        async fetchPostsByCategoryNews(id) {
            try {
                const data = await axios.get(`${API_BASE_URL}/api/categories/${id}/posts`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                });
                this.postsByCategoryNews = data.data;
            } catch (error) {
                console.error(error);
            }
        },
        async fetchPostsByCategory(id) {
            try {
                const data = await axios.get(`${API_BASE_URL}/api/categories/${id}/posts`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                });
                this.postsByCategory = data.data;
            } catch (error) {
                console.error(error);
            }
        },
    },
});
