import { defineStore } from "pinia";
import { API_BASE_URL } from '@/apiConfig';
import { accessToken } from '@/apiConfig';
import axios from "axios";

export const useLegalMentionStore = defineStore("LegalMentionStore", {

    state: () => ({
        legals: [],
    }),

    getters: {
        getLegals(state) {
            return state.legals;
        },
    },

    actions: {
        async fetchLegals() {
            this.legals = []
            try {
                const data = await axios.get(`${API_BASE_URL}/api/legal-mentions`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                this.legals = data.data;
                console.log('legals',this.legals)
            } catch (error) {
                console.error(error);
            }
        }
    },

});
